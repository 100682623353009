import { Profiler, useEffect, useState } from 'react';
import './App.css';
import { RenderRout } from './router';
import SplashScreen from './Component/Pages/SplashScreen';

function App() {
  const [load, setLoad] = useState(true)



  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setLoad(false);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
    
  },[]
  )
  return (

     load ? <SplashScreen/> : <RenderRout/>

  );
}

export default App;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReviewSvg } from '../../SvgFile/Index';

function Review() {
    const [position, setPosition] = useState({ top: null, left: null, bottom: '19px', right: '23px' });
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const disableScroll = (e) => {
        if (isDragging) {
            e.preventDefault();
        }
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffset({
            x: e.clientX - e.target.getBoundingClientRect().left,
            y: e.clientY - e.target.getBoundingClientRect().top,
        });
        document.addEventListener('wheel', disableScroll, { passive: false });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener('wheel', disableScroll);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newLeft = e.clientX - offset.x;
            const newTop = e.clientY - offset.y;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const constrainedLeft = Math.max(0, Math.min(newLeft, windowWidth - 50));
            const constrainedTop = Math.max(0, Math.min(newTop, windowHeight - 50));

            setPosition({
                top: `${constrainedTop}px`,
                left: `${constrainedLeft}px`,
                bottom: null,
                right: null,
            });
        }
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        setIsDragging(true);
        setOffset({
            x: touch.clientX - e.target.getBoundingClientRect().left,
            y: touch.clientY - e.target.getBoundingClientRect().top,
        });
        document.body.style.overflow = 'hidden';
    };

    const handleTouchMove = (e) => {
        if (isDragging) {
            const touch = e.touches[0];
            const newLeft = touch.clientX - offset.x;
            const newTop = touch.clientY - offset.y;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const constrainedLeft = Math.max(0, Math.min(newLeft, windowWidth - 50));  
            const constrainedTop = Math.max(0, Math.min(newTop, windowHeight - 50));  
            setPosition({
                top: `${constrainedTop}px`,
                left: `${constrainedLeft}px`,
                bottom: null,
                right: null,
            });
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        document.body.style.overflow = 'auto';
    };

    return (
        <Link to='/send-review'>
            <div
                className='commonreview'
                style={{ ...position, position: 'fixed' }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className='reviewlogo'>
                    <ReviewSvg />
                </div>
            </div>
        </Link>
    );
}

export default Review;

import { Usertype } from "../config";

const removeAuth = () => {
  if (JSON.parse(localStorage.getItem("userData")) === Usertype?.restaurant) {
    localStorage.removeItem("activeBranch");
  }
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
};
export { removeAuth };

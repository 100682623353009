import React, { useEffect, useState } from "react";
import { Usertype } from "../config";
import {
  AccountSvg,
  HomeSvg,
  ReviewSvg,
  HistorySvg,
  ProfileSvg,
  StoreFrontSvg,
  InviteFriendIcons,
  FeedBackIcons,
  AboutUs,
  FaceBookSvg,
} from "../SvgFile/Index";
import FooterLink, { NewHeader } from "./FooterLink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Review from "../Component/Review/Review";

export default function Footer() {
  const [links, setLinks] = useState([]);
  const [userHeader, setUserHeader] = useState(true);
  const [user, setUser] = useState(null);
  const [activeBranch, setActiveBranch] = useState(null);
  const [token, setToken] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // Fetch user data, active branch, and token from localStorage
    setUser(JSON.parse(localStorage.getItem("userData")));
    setActiveBranch(JSON.parse(localStorage.getItem("activeBranch")));
    setToken(localStorage.getItem("accessToken"));
  }, []);

  // Links for different user types
  const user_links = [
    location.pathname.includes("product-likes") ||
      location.pathname.includes("common-pairings")
      ? {
        link: `/in_restaurants/details/${location.pathname.split("/")[3]}`,
        title: t("store"),
        icone: <StoreFrontSvg />,
      }
      : location.pathname.includes("likes")
        ? {
          link: `/in_restaurants/details/${location.pathname.split("/")[2]}`,
          title: t("store"),
          icone: <StoreFrontSvg />,
        }
        : {
          link: "/",
          title: t("Home"),
          icone: <HomeSvg />,
        },

    {
      link: token ? "/my-profile" : "/account-type",
      title: token ? `${user?.name}` : t("Account"),
      icone: <AccountSvg />,
    },

    {

      link: "/invite-friend",
      title: t("Invitation"),
      icone: <InviteFriendIcons />,
    },
    {
      link: "",
      title: t("About Us"),
      icone: <AboutUs />
    },
    {
      link: "",
      title: t("Feedback"),
      icone: <FeedBackIcons />
    }, {
      link: "https://www.facebook.com/groups/1693315341196292/?ref=share_group_link",
      title: t("questions or feedback?"),
      icone: <FaceBookSvg />,
    },
  ];
  const driver_links = [];
  const restaurant_links = [
    {
      link: "/history",
      title: t("History"),
      icone: <HistorySvg />,
    },
    {
      link: token ? "/my-profile" : "/account-type",
      title: t("Account"),
      icone: <ProfileSvg />,
    },
    {
      link: `/in_restaurants/details/${activeBranch}`,
      title: t("Store Front"),
      icone: <StoreFrontSvg />,
    },
  ];

  useEffect(() => {
    if (user?.userType === Usertype.restaurant) {
      setLinks(restaurant_links);
      setUserHeader(false);
    } else if (user?.userType === Usertype.driver) {
      setLinks(driver_links);
    } else {
      setLinks(user_links);
    }
  }, [location.pathname, user, token, activeBranch]);

  const footerCss = () => {
    if (user?.userType === Usertype.restaurant) {
      return "newmenubottom";
    } else if (user?.userType === Usertype.driver) {
      return "";
    } else {
      return "mobilemenubottomnew";
    }
  };
  const filteredLinks = user_links.filter((item) => {

    if (!token && (item.title === t("Feedback") || item.title === t("Invitation"))) {
      return false;
    }
    return true;
  });
  return (
    <>
      {userHeader ? (
        <>
          <div className={`${footerCss()}`}>
            <button
              className="btn mymenu"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            >
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.27267 13.5455H18.7272C19.3272 13.5455 19.8181 13.0546 19.8181 12.4546C19.8181 11.8546 19.3272 11.3637 18.7272 11.3637H1.27267C0.672672 11.3637 0.181763 11.8546 0.181763 12.4546C0.181763 13.0546 0.672672 13.5455 1.27267 13.5455ZM1.27267 8.09095H18.7272C19.3272 8.09095 19.8181 7.60004 19.8181 7.00004C19.8181 6.40004 19.3272 5.90914 18.7272 5.90914H1.27267C0.672672 5.90914 0.181763 6.40004 0.181763 7.00004C0.181763 7.60004 0.672672 8.09095 1.27267 8.09095ZM0.181763 1.5455C0.181763 2.1455 0.672672 2.63641 1.27267 2.63641H18.7272C19.3272 2.63641 19.8181 2.1455 19.8181 1.5455C19.8181 0.945499 19.3272 0.45459 18.7272 0.45459H1.27267C0.672672 0.45459 0.181763 0.945499 0.181763 1.5455Z"
                  fill="#C51944"
                />
              </svg>
            </button>
            <div
              className={`offcanvas offcanvas-top ${token ? "navbarheight" : "navbarheightwithoutTokken"}`}
              tabIndex={-1}
              id="offcanvasTop"
              aria-labelledby="offcanvasTopLabel"
            >
              <div className="offcanvas-header">
                <img src="/image/logo-sm.png" className="img-fluid" alt="" />
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body hamburgerbody">
                <ul className="hamburgerlist">
                  {filteredLinks.map((item, i) => (
                    <NewHeader
                      key={i}
                      href={item.link}
                      name={item.title}
                      icon={item.icone}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <Review />
        </>
      ) : (
        <div className="footerbtm">
          <div
            className={`${footerCss()} d-flex align-items-center justify-content-between`}
          >
            {links.map((item, i) => (
              <FooterLink
                key={i}
                href={item.link}
                name={item.title}
                icon={item.icone}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

import React from 'react'
import Footer from './footer';

export default function HeaderLayout(props) {
  return (
    <>
   {props.children}
   <Footer/>
   </>
  )
}

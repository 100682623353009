import React from 'react'

const ConfirmButton = ({confrimText, clickfn, cancelFn, loader}) => {
  return (
    <div className='d-flex justify-content-end align-items-center gap-2 my-4'>
        <button className='btndarkblue' disabled={loader} onClick={clickfn}>{confrimText} {loader ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        <img src="/image/btn-arrow.png" className="img-fluid" alt="" />
      )}</button>
        <button className='btndarkblue-outline' onClick={cancelFn}>Cancel</button>
    </div>
  )
}

export default ConfirmButton
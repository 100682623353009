import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Usertype } from "../config";

const RestaurantGuard = ({ children }) => {
  const activeBranch = localStorage.getItem("activeBranch");
  const userType = JSON.parse(localStorage.getItem("userData"))?.userType
  const navigate = useNavigate();

  useEffect(() => {
    if(userType != Usertype?.restaurant){
      navigate("/")
    }
    else if (!activeBranch) {
      navigate("/branch");
    }
  }, [activeBranch, navigate]);

  return <>{children}</>;
};

export default RestaurantGuard;

 export const RestaurantUserAccess = ({children}) => {
    const userType = JSON.parse(localStorage.getItem("userData"))?.userType
    const navigate = useNavigate()

    useEffect(()=>{
        if(userType != Usertype?.restaurant){
            navigate("/")
        }
    }, [userType]);

    return <>{children}</>
}

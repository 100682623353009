export const apiUrls = {
    //Auth
    login:"login",
    verifyOtp:"otpVerify",
    uploadImageArr:"uploadImageArr",
    activerestaurant:"activerestaurant",
    restauantdetiels:"restauantDetail",
    profileData:"userData",
    uploadOcr:"uploadOcr",
    addRestaurantReview:"addRestaurantReview",
    branchLogin:"restaurantProfileData",
    //category
    categoryList:"listFoodCategoryForUser",
    addCategory:"addFoodCategoryByUser",
    //menu
    addRestaurantMenu:"addRestaurantMenu",
    editRestaurantMenu:"updateRestaurantMenu",
    deleteRestaurantMenu:"deleteRestaurantMenu",
    getMenuDetails:"viewRestaurantMenu",
    menuList:"listRestaurantMenu",
    restaurantViewMenulist:"listingRestaurantMenu",
    commonPairingList:"listingMenuPairing",
    reviewRestaurantListing:"reviewRestaurantListing",
    approveRejectRestaurantReview:"approveRejectRestaurantReview",
    leaderBoardMenu:"leaderBoardOfMenu",
    totalRestaurantLike:"totalRestaurantLike",
    leaderBoardOfUniqueMenu:"leaderBoardOfUniqueMenu",
    userRestaurantLeader:"userRestaurantLeader",
    //invite
    inviteAfriend:"userinvite",
    inviteRestaurant:"inviteRestaurant",
    userReviewListing:"userDataByWeek",
    //logoclick
    storeRestaurantOrderHistory:"orderPlatform",
    //feedback
    addFeedback:"addFeedback",
    platformFeedback:"addplatformFeedback",
    userReviewLeaderBoardListing:"userReviewLeaderBoardListing"
};

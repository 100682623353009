import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import downPopUp from '../Component/DownPopUp/DownPopUp';
import { ErrorMessage, SuccessMessage } from '../helpers/common';
import { useTranslation } from "react-i18next";
import { apiUrls } from '../utils/apiUrls';
import { callAPI } from '../utils/apiUtils';
import SimpleReactValidator from 'simple-react-validator';
import ConfirmButton from '../Component/ConfrimButon.js/ConfirmButton';

export default function FooterLink({ href, name, icon }) {

  return (
    <>
      <NavLink to={href} className={({ isActive, isPending }) =>
        isPending ? "" : isActive ? "active" : ""
      }>
        {icon}
        {name}

      </NavLink>
    </>
  )
}
export const NewHeader = ({ href, name, icon }) => {
  const [feedbackMsg, setFeedbackMsg] = useState({ message: "" });
  const [btnloader, setBtnLoader] = useState(false);
  const [feedbackopen, setFeedbackopen] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  simpleValidator.current.purgeFields();
  const [, forceUpdate] = useState();
  const userNavidate = useNavigate()
  const { t } = useTranslation();
  const handleOverflowProperty = () => {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  }
  const SubmitFeedback = async () => {
    if (feedbackMsg?.message.trim()?.length > 0) {
      try {
        const response = await callAPI(
          apiUrls.addFeedback,
          {},
          "POST",
          feedbackMsg
        );
        if (response?.data?.success) {
          SuccessMessage(response?.data?.message);
          setFeedbackMsg({ message: "" });
          setFeedbackopen(false);
        } else {
          ErrorMessage(response?.data?.message);
        }
      } catch (error) {

      }
    }
  };

  const submitFeedbackFn = async (e) => {
    if (formValid()) {
      e.preventDefault();
      await SubmitFeedback();
      simpleValidator.current.hideMessages();
      forceUpdate(1);
    }
  };
  const formValid = () => {
    const allFieldsValid = simpleValidator.current.allValid();
    if (!allFieldsValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else return true;
  };
  const handleUserNavidate = () => {
    if (href.startsWith("http")) {
      window.open(href, "_blank", "noopener,noreferrer");
    } else {
      userNavidate(href)
    }
    handleOverflowProperty()
    if (name === "Feedback") {
      setFeedbackopen(true)
    }
  }

  return (
    <>
      <li onClick={handleUserNavidate} className='text-reset' data-bs-dismiss="offcanvas">
        <span>{icon}</span>
        <NavLink className={({ isActive }) => (isActive ? 'active-link ' : '')} >
          {name}
        </NavLink>

      </li>
      {downPopUp(
        feedbackopen,
        t("Send Your Feedback"),
        FeedbackInput(
          submitFeedbackFn,
          feedbackMsg,
          setFeedbackMsg,
          setFeedbackopen,
          btnloader,
          simpleValidator
        )
      )}


    </>
  );
};


const FeedbackInput = (
  submitFn,
  feedbackMsg,
  setFeedbackMsg,
  setOpen,
  btnloader,
  simpleValidator
) => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h6 className="text-secondary mb-3">{t("Feedback Message")} *</h6>
      <textarea
        className="form-control formarea"
        placeholder="Add your comment here..."
        id="exampleFormControlTextarea1"
        name="description"
        rows={4}
        value={feedbackMsg.message}
        onChange={(e) => {
          setFeedbackMsg((val) => ({ ...val, message: e.target?.value }));
        }}
      />
      <div className="error">
        {simpleValidator.current.message(
          "Feedback",
          feedbackMsg.message,
          "required"
        )}
      </div>
      <ConfirmButton
        confrimText={t("Send Feedback")}
        loader={btnloader}
        clickfn={async (e) => {
          await submitFn(e);
        }}
        cancelFn={(e) => {
          e.preventDefault();
          setOpen(false);
          setFeedbackMsg({ message: "" });
        }}
      />
    </div>
  );
};
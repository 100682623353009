import React from "react";
import Dialog from "@mui/material/Dialog";

const downPopUp = (open, heading, WrappedComponent) => {

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            position: "absolute",
            width: "100%",
            left: "0",
            bottom: "0",
            right: "0",
            margin: "auto",
            borderRadius: "30px 30px 0px 0px",
            // Set your width here
          },
        },
      }}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header p-0 border-bottom-0 position-relative justify-content-center"></div>
          <div className="modal-body px-0 py-0">
          <div className="modelheading  mt-3 mb-4">
            <h1>{heading}</h1>
            </div>
            {WrappedComponent.props.children}
          </div>
          
        </div>
      </div>
    </Dialog>
  );
};

export default downPopUp;
